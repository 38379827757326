import axios from "axios";
import {config} from "../config";
import {RunInstanceResponse} from "../dto/run-instance-response";
import {GetInstancesResponse, InstanceData} from "../dto/get-instances-response";
import {RunInstanceRequest} from "../dto/run-instance-request";
import {StopInstanceRequest} from "../dto/stop-instance-request";
import {GetLogsResponse} from "../dto/get-logs-response";
import {GetRecentlyUsedImagesResponse} from "../dto/get-recently-used-images-response";
import { SpacelessConfig } from '../dto/spaceless-config';
import { JipQuota } from '../dto/jip-quota';
import { FileDownloadResponse } from '../dto/file-download-response';

export namespace API {

    export async function runBackend(request: RunInstanceRequest): Promise<RunInstanceResponse> {
        console.log("running a backend");
        const response = await axios.post(`${config.apiHost}/instances/run`, request, {withCredentials: true});
        return response.data as RunInstanceResponse;
    }

    export async function getInstances(): Promise<GetInstancesResponse> {
        console.log("loading a list of instances");
        const response = await axios.get(`${config.apiHost}/instances/`, {withCredentials: true});
        return response.data.map((e: any) => InstanceData.parse(e));
    }

    export async function terminateAll(): Promise<any> {
        console.log("terminating all jobs");
        return axios.post(`${config.apiHost}/instances/exterminatus`, null, {withCredentials: true, headers: {"Content-Type": "application/json"}});
    }

    export async function getLink(workspaceId: string, legacyJoinLinks: boolean): Promise<string> {
        console.log("retrieving connection link for the job " + workspaceId);
        const response = await axios.get(`${config.apiHost}/instances/link`, {params: {workspaceId, legacyJoinLinks}, withCredentials: true});
        return response.data.link as string;
    }

    export async function stopInstance(request: StopInstanceRequest): Promise<any> {
        const workspaceId = request.workspaceId;
        console.log("stopping instance with jobName " + workspaceId);
        return axios.delete(`${config.apiHost}/instances/stop`, {params: {workspaceId}, withCredentials: true});
    }

    export async function getInstanceLogs(workspaceId: string): Promise<string | null> {
        console.log(`loading logs for instance with workspaceId=${workspaceId}`);
        const response = await axios.get(`${config.apiHost}/instances/logs`, {params: {workspaceId}, withCredentials: true});
        return (response.data as GetLogsResponse).log ?? null;
    }

    export async function getIdeLogs(workspaceId: string): Promise<FileDownloadResponse> {
        console.log(`loading IDE logs for instance with workspaceId=${workspaceId}`);
        const response = await axios.get(`${config.apiHost}/instances/ide-logs`, {params: {workspaceId}, withCredentials: true});
        return response.data as FileDownloadResponse;
    }

    export async function getRecentlyUsedImages(): Promise<GetRecentlyUsedImagesResponse> {
        console.log("loading recently used runtime images");
        const response = await axios.get(`${config.apiHost}/misc/recent-images`, {withCredentials: true});
        return response.data as GetRecentlyUsedImagesResponse;
    }

    export async function wipeCache(): Promise<any> {
        console.log("wiping the cache");
        return axios.post(`${config.apiHost}/misc/wipe-cache`, {}, {withCredentials: true});
    }

    export async function getSpacelessConfig(): Promise<SpacelessConfig> {
        console.log("loading scaling config");
        const response = await axios.get(`${config.apiHost}/misc/config`, {withCredentials: true});
        return response.data as SpacelessConfig;
    }

    export async function getResources(): Promise<JipQuota> {
        console.log("Loading resources");
        const response = await axios.get(`${config.apiHost}/misc/resources`, {withCredentials: true});
        return response.data as JipQuota;
    }

}
